import React, { useState, useEffect, useRef, useMemo } from 'react'
import * as THREE from 'three'
import { Canvas, extend, useFrame, useResource, useThree } from 'react-three-fiber'
import { Controls, useControl } from 'react-three-gui'
import { animated, useSpring } from '@react-spring/three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { DirectionalLightHelper } from 'three/src/helpers/DirectionalLightHelper'
import { GUI } from 'dat.gui';

extend({ OrbitControls, TransformControls })

class ColorGUIHelper {
  constructor(object, prop) {
    this.object = object;
    this.prop = prop;
  }
  get value() {
    return `#${this.object[this.prop].getHexString()}`;
  }
  set value(hexString) {
    this.object[this.prop].set(hexString);
  }
}

class DegRadHelper {
  constructor(obj, prop) {
    this.obj = obj;
    this.prop = prop;
  }
  get value() {
    return THREE.MathUtils.radToDeg(this.obj[this.prop]);
  }
  set value(v) {
    this.obj[this.prop] = THREE.MathUtils.degToRad(v);
  }
}

class MinMaxGUIHelper {
  constructor(obj, minProp, maxProp, minDif) {
    this.obj = obj;
    this.minProp = minProp;
    this.maxProp = maxProp;
    this.minDif = minDif;
  }
  get min() {
    return this.obj[this.minProp];
  }
  set min(v) {
    this.obj[this.minProp] = v;
    this.obj[this.maxProp] = Math.max(this.obj[this.maxProp], v + this.minDif);
  }
  get max() {
    return this.obj[this.maxProp];
  }
  set max(v) {
    this.obj[this.maxProp] = v;
    this.min = this.min;  // this will call the min setter
  }
}

const MAX = 5
function makeXYZGUI(gui, vector3, name, onChangeFn) {
  const folder = gui.addFolder(name);
  const x = folder.add(vector3, 'x', -MAX, MAX).onChange(onChangeFn);
  const y = folder.add(vector3, 'y', 0, MAX).onChange(onChangeFn);
  const z = folder.add(vector3, 'z', -MAX, MAX).onChange(onChangeFn);
  folder.open();
  return { x, y, z };
}

export default function Banner({model, camera, ...props}) {
  const [fontModel, setFontModel] = useState()
  useEffect(() => {
    console.log('in effect')
    new GLTFLoader().parse(JSON.stringify(model), '', setFontModel)
  }, [model]);

  return <>
    <Canvas
      orthographic={true}
      camera={camera}
    >
      <ambientLight />
      { fontModel ? <TextBanner fontModel={fontModel} {...props} /> : 'Loading...' }
    </Canvas>
  </>;
}

function setLetterPositions(letters) {
  letters.forEach(letter => {
    const box = new THREE.Box3().setFromObject(letter);
    const {x, y, z} = box.getCenter();
    letter.geometry.center();
    letter.position.set(x, y, z);
  });
}

function TextBanner({fontModel, text, ...props}) {
  const { camera, gl, size, scene } = useThree()
  console.log('camera, size, scene', camera, size, scene);
  const [mode, setMode] = useState('scale');
  const gui = useMemo(() => {
    const gui = new GUI();
    gui.add({mode:'scale'}, 'mode', ['scale', 'rotate', 'translate']).onChange(setMode);
    return gui;
  }, []);
  window[text] = camera;
  const ref = useRef()
  const orbit = useRef()
  const transform = useRef()
  useFrame(() => orbit.current && orbit.current.update())
  useEffect(() => {
    if (transform.current) {
      const controls = transform.current
      console.log('transform setMode', mode);
      controls.setMode(mode);
      controls.attach(ref.current);
      const callback = event => {
        console.log('dragging-changed', event);
        orbit.current.enabled = !event.value;
      }
      controls.addEventListener('dragging-changed', callback)
      return () => controls.removeEventListener('dragging-changed', callback)
    }
  }, [transform, orbit, mode])

  const letters = fontModel.scene.children;
  setLetterPositions(letters);

  return <>
    <Text ref={ref} gui={gui} text={text} letters={letters} {...props} />
    <transformControls
      ref={transform}
      size={3}
      args={[camera, gl.domElement]}
      onUpdate={self => {
        self.attach(ref.current);
      }}
    />
    <orbitControls ref={orbit} args={[camera, gl.domElement]} enableDamping dampingFactor={0.1} rotateSpeed={0.5} />
  </>;
}

const Text = React.memo(React.forwardRef(({gui, text, letters, ...props}, ref) => {
  const { camera, scene } = useThree()
  const { light, lightHelper } = useMemo(() => {
    const light = new THREE.SpotLight({color: '#fff', intensity: 1, position: [-1, 2, 4]});
    const lightHelper = new THREE.DirectionalLightHelper(light)
    return { light, lightHelper };
  }, []);
  const [animateLightX, setAnimateLightX] = useState(true);
  function updateLight () {
    light.target.updateMatrixWorld();
    lightHelper.update();
  }
  const material = useMemo(() => new THREE.MeshPhongMaterial({color: '#0e3934', transparent: true}), []);
  useEffect(() => {
    scene.add(light);
    light.add(lightHelper);

    const { x } = makeXYZGUI(gui, light.position, 'light position', () => {
      updateLight();
      setAnimateLightX(false);
    });
    x.listen();
    makeXYZGUI(gui, light.target.position, 'light target', updateLight);

    function updateCamera() {
      camera.updateProjectionMatrix()
      cameraHelper.update()
    }

    const cameraHelper = new THREE.CameraHelper(camera);
    const cameraFolder = gui.addFolder('camera');
    cameraFolder.add(camera, 'zoom', 1, 300, 1).onChange(updateCamera);
    const minMaxGUIHelper = new MinMaxGUIHelper(camera, 'near', 'far', 0.1);
    cameraFolder.add(minMaxGUIHelper, 'min', 0.01, 50, 0.01).name('near').onChange(updateCamera);
    cameraFolder.add(minMaxGUIHelper, 'max', 0.01, 50, 0.01).name('far').onChange(updateCamera);
    cameraFolder.open();

    gui.addColor(new ColorGUIHelper(material, 'color'), 'value').name('color');
  }, []);

  const initialLightX = -5;
  const { x } = useSpring({
    from: { x: initialLightX },
    to: async next => {
      while (animateLightX) {
        await next({ x: 5 });
        await next({ x: -5 });
      }
    },
    config: {
      mass: 0.1,
      tension: 200,
      friction: 180
    }
  });

  useFrame(() => {
    if (!animateLightX) return;

    light.position.x = x.value;
    updateLight();
  });

  return (
    <group ref={ref}>
      {
        text.split('').map((letter, index) => (
          <Letter key={index} letter={letter}
            material={material}
            position={letters[index].position}
            geometry={letters[index].geometry} {...props}
        />
      ))}
    </group>
  );
}));

function Letter({geometry, letter, color, ...props}) {
  const [active, setActive] = useState(false)
  const [hovered, setHover] = useState(false)

  const springProps = useSpring({
    'material-opacity': hovered ? 0.8 : 2,
    scale: active ? [1.5, 1.5, 1.5] : [1, 1, 1],
    rotation: hovered ? [-0.2, 0.1, 0] : [0, 0, 0]
  })

  return (
    <animated.mesh
      onClick={() => {
        console.log('click', letter)
        setActive(!active)
      }}
      onPointerOver={e => {
        console.log('hover', letter)
        setHover(true)
      }}
      onPointerOut={e => {
        console.log('unhover', letter)
        setHover(false)
      }}
      geometry={geometry}
      {...props}
      {...springProps}
    >
    </animated.mesh>
  );
}
