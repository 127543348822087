import styled, { createGlobalStyle } from 'styled-components/macro';
import { COLOR, FONT } from './theme';

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
  body {
    background: ${COLOR.defaultBackground};
    color: ${COLOR.defaultText};
    font-family: ${FONT.defaultFamily};
    input, textarea {
      padding: 0.5rem;
    }
  }
  canvas {
    outline: none;
  }

  .Resizer {
    background: #000;
    opacity: 1;
    box-sizing: border-box;
    background-clip: padding-box;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -1px 0;
    border-top: 1px solid rgba(26, 103, 94, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Pane:hover + .Resizer.horizontal,
  .Resizer.horizontal:hover {
    transition: all 2s ease;
    border-top: 1px solid rgba(26, 103, 94, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  div.dg.ac {
    z-index: 10;
  }
`;

export const Main = styled.main`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
`;
