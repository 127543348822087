// TODO: Use `styled-theming`.
// TODO: Remove unused values.

export const COLOR = {
  white: '#fff',
  brandBackground: '#495497',
  defaultBackground: '#000',
  defaultText: '#898da5',
  headerText: '#4a4a4a',
  linkText: '#4a90e2',
  buttonBackground: '#cfcfcf',
  buttonBackgroundDisabled: '#e4e4e4',
  buttonTextDisabled: '#abadbb',
  listAltRow: '#f2f2f2',
  divider: '#979797'
};

export const FONT = {
  defaultFamily: 'Roboto'
};

export const MOVEMENT = {
  hover: `
    transition: transform 0.2s ease-out;
    &:hover {
      transform: scale(1.03);
    }
  `,
  hoverSmall: `
    transition: transform 0.2s ease-out;
    &:hover {
      transform: scale(1.2);
    }
  `
};
