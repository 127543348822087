import React, { useCallback, useEffect, useRef, useState } from 'react';
import SplitPane from 'react-split-pane';
import { Reset } from 'styled-reset';
import { Terminal } from '@techanvil/xterm-react-renderer'

import Banner from './Banner';
import { GlobalStyle, Main } from './App.styled';

import textModel from './font/tlwg-mono/terminal-ninja.json'

function App() {
  const [maxWidth, setMaxWidth] = useState('1440px');
  const onDragFinished = newHeight => setMaxWidth(`${newHeight / 180 * 1440}px`);

  return <>
    <Reset />
    <GlobalStyle />
    <Main>
      <SplitPane split="horizontal" minSize={180} defaultSize={180} onDragFinished={onDragFinished}>
        <section style={{ position: 'relative', width: '100%', maxWidth, height: '100%'}}>
          <Banner
            camera={{ position: [0, 0.5, 0], zoom: 200 }}
            text="terminal.ninja" model={textModel} />
        </section>
        <section style={{position: 'relative', width: '100%', height: '100%'}}>
          <Terminal />
        </section>
      </SplitPane>
    </Main>
  </>;
}

export default App;
